.page {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 12px;
  height: 100%;
  justify-content: center;
  list-style: none;
  margin: 0;
  overflow-y: scroll;
  padding: 0 0 0 10px; // adjusts left to account for scrollbar
  width: 100%;
}

.page::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 10px;
}

.page:hover::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
  .page:hover::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.section {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;

  button {
    margin-top: 8px;
  }
}

.inputGroup {
  width: 100%;
  padding: 0 8px;

  label {
    display: block;
    font-size: 10pt;
    margin-bottom: 4px;
    text-align: start;
    width: 100%;
  }

  input {
    display: block;
    width: 100%;
  }
}
