@keyframes spinAndScale {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.8);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.spinner {
  animation: spinAndScale 0.75s 0s infinite linear;
  animation-fill-mode: both;
  border-radius: 100%;
  border: 4px solid;
  border-color: white;
  border-bottom-color: transparent;
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  width: 20px;
}
