.button {
  border: none !important;
  border-radius: 25px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  transition: 0.05s ease-in;
  outline: 0;
  user-select: none;

  &:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.35);
    opacity: 0.9;
  }
  &:active {
    opacity: 1;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    cursor: not-allowed;
  }

  /**
   * Style options
   */
  &.primary {
    background: #8b65c7;
    color: white;
  }

  &.secondary {
    background: #6b72c9;
    color: white;
  }

  /**
   * Size options
   */
  &.large {
    height: 50px;
    width: 160px;
    font-size: 16px;
  }

  &.medium {
    height: 38px;
    width: 128px;
    font-size: 14px;
  }
}
