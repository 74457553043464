.backgroundList {
  padding: 10px 0 20px;
  width: 100%;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.backgroundList::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 10px;
}

.backgroundList:hover::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.backgroundDiv {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 80vh;
}

.searchBar {
  height: 2em;
  background: #f3f3f3;
  border-radius: 0px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 11px;
  float: left;
  margin: 4px;

  input {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 768px) {
  .backgroundList {
    flex-direction: row;
    height: auto;
    overflow-x: auto;
    overflow-y: unset;
    padding: 0 15px 10px;
  }

  .backgroundList::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}
