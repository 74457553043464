.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

.select {
  background-color: white;
  border-color: none;
  border-radius: 35px;
  border: 1px solid;
  color: #4a57b9;
  cursor: pointer;
  font-family: 'Open Sans Semi Bold', sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  position: relative;
  text-transform: capitalize;
  transition: 0.2s ease;
  z-index: 1;
  width: 100%;
  text-overflow: ellipsis;
  transition: 0.05s ease-in;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.8;
  }
  &:disabled {
    color: #444;
    opacity: 0.2;
    pointer-events: none;
  }
}

.label {
  flex-grow: 0;
  margin-left: 10px;
  font-size: 0.8rem;
  color: #444;
  display: none;
}

.errorMessage {
  color: red;
  font-size: 0.7rem;
}
