.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spacer {
  display: inline-block;
  width: 19px;
  padding: 4px;
}

.accountInfo {
  display: inline-block;
  transition: all 0.05 ease-in;
  padding: 6px;

  &:hover {
    background-color: #eaeaef;
    border-radius: 8px;
    // filter: brightness(0.85);
    cursor: pointer;

    .address span {
      filter: brightness(0.7);
    }
  }
}

.title {
  color: #353535;
  font-size: 12pt;
  margin: 0;
  padding: 0;
  text-align: center;
  user-select: none;
}

.address {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  gap: 2px;

  svg {
    user-select: none;
  }

  span {
    color: #959de3;
    font-size: 10pt;
    user-select: none;
  }
}

.signOut {
  transition: all 0.05 ease-in;

  &:hover {
    filter: brightness(0.6);
    cursor: pointer;
  }

  svg {
    padding: 4px;
  }
}
