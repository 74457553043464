.thumbnail {
  background: none;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: grid;
  flex-shrink: 0;
  height: 140px;
  overflow: hidden;
  padding: 0;
  place-items: center;
  position: relative;
  width: 140px;

  &:focus-visible {
    img {
      border-color: var(--sidebar-accent-light);
    }
  }
  &:hover,
  &:focus-visible {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }

  &.active {
    img {
      border-color: var(--sidebar-accent);
    }
  }

  img {
    border: 3px solid transparent;
    object-fit: cover;
    transition: 0.05s ease-in;
  }
}
