.loaderContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  text-align: center;
  color: white;
  gap: 10px;
  & p {
    margin: 0;
  }
  & a {
    color: #98a3f5;
  }
}

.loaderText {
  font-size: 18pt;
  font-weight: 600;
  text-align: center;
}

.loaderSubText {
  font-size: 12pt;
}
