.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-items: center;
  align-items: center;

  h1 {
    display: inline-block;
    flex-grow: 1;
    margin: 0;
    padding: 20px 0;
  }

  .testAddress {
    flex-grow: 0;

    label {
      font-size: 0.8rem;
    }

    input {
      margin-right: 4px;
    }

    button {
      background: #8a66c6;
      border-radius: 2px;
      border: #8a66c6 2px solid;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);

      color: white;
      cursor: pointer;

      transition: 0.05s ease-in;
      outline: 0;
      &:hover {
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.35);
        opacity: 0.9;
      }
      &:active {
        opacity: 1;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
      }
      &:focus-visible {
        border-color: #2d39ad;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    margin-left: 10px;
  }
}
