.connectWallet {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  margin-top: 3rem;

  &.signedOut {
    display: none;
    margin-top: 0px;
  }
}

@media screen and (max-width: 768px) {
  .connectWallet.signedOut {
    display: block;
  }
}

.container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.walletEmpty {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  padding: 0px 20px;
}

.inventoryList {
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

.inventoryList::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 10px;
}

.inventoryList:hover::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
  .inventoryList {
    position: relative;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: unset;
    padding: 0 15px 10px;

    &.signedOut {
      display: none;
    }
  }

  .inventoryList::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.thumbnail {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0;
  width: 100%;
  height: auto;
  border: 3px solid transparent;
  transition: 0.05s ease-in;
  cursor: pointer;
  &:focus-visible {
    border-color: var(--sidebar-accent-light);
  }
  &:hover,
  &:focus-visible {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.9;
  }

  background: none;
  padding: 0;
  display: grid;
  place-items: center;
  &:focus {
    outline: none;
  }

  &.active {
    border-color: var(--sidebar-accent);
  }
}
