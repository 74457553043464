.page {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 12px;
  height: 100%;
  justify-content: center;
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  width: 100%;
}

.page::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0);
  width: 10px;
}

.page:hover::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
  .page:hover::-webkit-scrollbar-thumb {
    background-color: #999999;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.section {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.deviceSelectContainer {
  max-width: 108px;
}

.warning {
  margin: 4px;
  padding: 4px 8px;
  font-size: 9pt;
  background-color: #fafa97;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);

  a {
    color: #6b72c9;
    font-weight: 400;
    text-decoration: none;
    transition: opacity 0.05s ease-in;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
