.iconButton {
  all: unset;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: 0.05s ease-in;
  & * {
    box-sizing: border-box;
  }
  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    filter: brightness(0.8);
  }
}

.iconButtonActive .iconContainer {
  color: var(--icon-accent);
}

.iconLabel {
  font-size: 8pt;
  font-weight: 600;
}

.iconContainer {
  background-color: var(--icon-bg);
  border: none;
  width: 44px;
  height: 44px;
  padding: 10px;
  display: grid;
  place-items: center;
  border-radius: 9999px;
  color: var(--icon-fill);
  & svg {
    width: 100%;
    height: 100%;
  }
  & svg * {
    fill: currentColor;
  }
  .iconButton:focus-visible & {
    outline: solid var(--icon-accent);
  }
}
