.container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;

  & p {
    margin: 0;
  }
  & a {
    color: #98a3f5;
  }
}

.loaderText {
  font-size: 18pt;
  font-weight: 600;
  text-align: center;
  max-width: 500px;
}

.loaderSubText {
  font-size: 12pt;
  line-height: 1.6;
  max-width: 500px;
}
