.container {
  user-select: none;
}

.title {
  margin: 0;
}

.description {
  margin: 18px 20px 24px 20px;
}

.walletButton {
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  padding: 12px 16px;
  width: 100%;
  transition: all 0.3s;

  &:hover {
    background-color: #f3f4fe;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.walletLogo {
  margin-right: 20px;
}

.nameContainer {
  flex-grow: 1;
  text-align: start;
}

.walletName {
  margin: 0;
  font-size: 12pt;
  font-weight: bold;
}

.comingSoon {
  margin-top: 2px;
  font-size: 9pt;
  color: #aeaeca;
  font-weight: normal;
}

.hideWalletOption {
  display: none;
}
