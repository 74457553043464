.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .container {
    height: 100%;
  }
}

.image {
  width: 140px;
  height: auto;
  margin: 10px 0px;
}

@media screen and (max-width: 768px) {
  .image {
    width: 100px;
    margin: 0px 0px 6px 0px;
  }
}

.message {
  color: #4d5176;
  font-size: 10pt;
  font-weight: 600;
  max-width: 158px;
  text-align: center;
  margin: 0 0 16px 0px;
}

@media screen and (max-width: 768px) {
  .message {
    max-width: 200px;
  }
}

.refresh {
  color: #6b72c9;
  font-size: 9pt;
  font-weight: 400;
  margin: 4px 0px;
  opacity: 0.6;
  padding: 12px 0px;
  text-align: center;
  transition: opacity 0.05s ease-in;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .refresh {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}
