.container {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.popoverButton {
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

@media screen and (max-width: 768px) {
  .container {
    height: 100%;
  }
}

.image {
  width: 140px;
  height: auto;
  margin: 10px 0px;
}

@media screen and (max-width: 768px) {
  .image {
    display: none;
  }
}

.message {
  color: #4d5176;
  font-size: 10pt;
  font-weight: 600;
  max-width: 158px;
  text-align: center;
  margin: 0 0 16px 0px;
}

.link {
  margin: 4px 0px;
  padding: 12px 0px;
  text-align: center;

  a {
    color: #6b72c9;
    font-size: 9pt;
    font-weight: 400;
    opacity: 0.6;
    text-decoration: none;
    transition: opacity 0.05s ease-in;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 768px) {
  .link {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}
