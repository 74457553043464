:root {
  --icon-accent: #6b72c9;
  --icon-fill: #a4a4a4;
  --icon-bg: #f6f6f6;
  --sidebar-accent: #2d39ad;
  --sidebar-accent-light: #6b72c9;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.app {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  overflow: hidden;
}

.container {
  display: grid;
  grid-template-rows: 1fr 86px;
  height: 100%;
  padding-left: 30px;
}

.content {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
  min-height: 0;
}

.sceneContainer {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

.main {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: 80px 1fr;
}

.controlBar {
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 2;
  position: relative;
}

.video {
  position: absolute;
  top: 16px;
  right: 16px;
  max-width: 160px;
  height: auto;
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.05s ease-in;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.loadingContainerHidden {
  opacity: 0;
  visibility: hidden;
}

.cameraButtonsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 0;
  align-items: center;
  gap: 18px;
}

.goLiveButtonContainer {
  position: relative;
  flex-grow: 1;
}

.deviceSelectContainer {
  position: relative;
  flex-grow: 1;
  display: block;
}

.deviceSelectContainerHidden {
  display: none;
}

.zStack {
  position: relative;
}

.zStack > * {
  position: absolute;
  width: 100%;
  height: 100%;
}

.iconWalletContainer {
  width: 200px;
  display: flex;
  justify-content: center;
  & svg {
    color: var(--icon-accent);
  }
}

.iconButtonsContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  gap: 18px;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.recordIndicator {
  animation: blink 1s step-start infinite;
  background-color: #ef3636;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 16px;
}

.iconWalletMobile {
  display: none;
}

.mobileOnly {
  display: hidden;
}

@media screen and (max-width: 768px) {
  .mobileOnly {
    display: initial;
  }
  .desktopOnly {
    display: hidden;
  }
}

.mobileSideBar {
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  z-index: 3;
  padding-top: 12px;
  max-height: 0;
  width: 100%;
  transition-timing-function: ease-in;
  transition: 0.25s;
  transform: translateY(130%);
}

.openMobileSideBar {
  max-height: 240px;
  transition-timing-function: ease-out;
  transition: 0.25s;
  transform: translateY(0);
}

.closeIconContainer {
  display: none;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 24px;
}

.closeIcon {
  width: 16px;
  fill: var(--icon-accent);
  cursor: pointer;
  position: fixed;
  right: 12px;
  top: 12px;
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0px;
    height: auto;
  }

  .sideBarContainer {
    display: none;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 100vw;
  }

  .main {
    display: block;
    height: unset;
  }

  .controlBar {
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px 10px;
    width: 100%;
  }

  .cameraButtonsContainer {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .deviceSelectContainer {
    margin: 10px 0;
    width: 100%;
  }

  .iconWalletContainer {
    display: none;
  }

  .iconButtonsContainer {
    gap: 0;
    justify-content: space-evenly;
    margin: 10px;
    text-align: center;
    width: 100%;
  }

  .sceneContainer {
    width: 100vw;
    height: 100vw;
  }

  .video {
    display: none;
  }

  .iconWalletMobile {
    display: block;
  }

  .goLiveButtonContainer {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
  }

  .goLiveButton {
    width: 100% !important;
  }

  .deviceSelectContainer {
    flex-grow: 1;
    margin: 0;

    select {
      height: 50px !important;
    }
  }

  .mobileSideBar {
    visibility: visible;
  }

  .closeIconContainer {
    display: block;
  }
}
