.panel {
  background-color: #fff;
  position: absolute;
  width: 280px;
  right: 0px;
  bottom: 100px;
  padding: 24px 20px;
  box-shadow: 0px 3px 15px rgb(0 0 0 / 25%);
  border-radius: 6px;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .panel {
    width: auto;
    right: 16%;
    left: 16%;
  }
}

.panelEnter {
  transition: all 0.2s ease-out;
}

.panelEnterFrom {
  opacity: 0;
  transform: translate(0, 0.25rem);
}

.panelEnterTo {
  opacity: 1;
  transform: translate(0);
}

.panelLeave {
  transition: all 0.2s ease-out;
}

.panelLeaveFrom {
  opacity: 1;
  transform: translate(0);
}

.panelLeaveTo {
  opacity: 0;
  transform: translate(0, 0.25rem);
}
